import { render, staticRenderFns } from "./WidgetModelsReports.vue?vue&type=template&id=f8a4a73e&"
import script from "./WidgetModelsReports.vue?vue&type=script&lang=ts&"
export * from "./WidgetModelsReports.vue?vue&type=script&lang=ts&"
import style0 from "./WidgetModelsReports.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})
